/*
 * @Author: 宋绍华
 * @Date: 2022-11-04 11:59:47
 * @LastEditTime: 2022-11-17 17:31:32
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\composables\useProfileOverview.ts
 */
import { getTaskList } from "@/api/request-modules/iteration";
import {
  getProfileInfoApi,
  getProfileOverviewChartsApi,
  getProfileOverviewInfoApi,
  getProfileProjectListApi,
  getQualityIterateList
} from "@/api/request-modules/lookboard";
import { getBugListApi } from "@/api/request-modules/test";
import { awaitFunc, STATUS } from "@/utils";

// table分页基础interface
export interface TableListBaseReq {
  page_index?: number;
  page_size?: number;
}

export interface IteraterReqSearch extends TableListBaseReq {
  product_id?: number;
  iteration_id?: number[] | number;
  status?: number[];
  staff_no?: string;
}

export interface TaskReqSearch extends IteraterReqSearch {
  name?: string;
  level?: number;
}

export interface QualityResp {
  count: number; // 总数量;
  released_num: number; // 已发布数量;
  doing_num: number; // 进行中数量;
  delayed_num: number; // 延期数量;
  total_task_hours: number; // 所有任务总工时;
  avg_task_hours: number; // 所有任务平均工时;
  iteration_precision_rate: number; // 迭代准点率;
  defect_density: number; // 平均缺陷密度;
  list: QualityRespItem[];
}

export interface QualityRespItem {
  version_name: string; // 版本号名称;
  status: number; // 状态;
  complete_percent: number; // 进度;
  ahead_percent?: number; // 提前进度;
  behind_percent?: number; // 落后进度;
  task_hours: number; // 任务总工时;
  task_precision_rate: number; // 任务准点率;
  task_average_hour: number; // 任务平均工时;
  apply_rejected_num: number; // 提测驳回数量;
  bug_num: number; // bug数量;
  defect_density: number; // 缺陷密度;
  dev_time: string; // 计划开发时间;
  union_time: string; // 计划联调时间;
  test_time: string; // 计划测试时间;
  release_time: string; // 计划发布时间;
  real_dev_time: string; // 实际开发时间;
  real_union_time: string; // 实际联调时间;
  real_test_time: string; // 实际测试时间;
  real_release_time: string; // 实际发布时间;
  id: number; // 迭代ID;
}

export interface TableListBaseResp<T> {
  count: number;
  list: T[];
}

export interface TaskList {
  count: number;
  list: TaskListItem[];
}

export interface TaskListItem {
  staff_name: string;
  iteration_name: string;
  hours: number;
  real_end_time: string;
  level: number;
  complete_percent: number;
  end_time: string;
  begin_time: string;
  remark: string;
  type: number;
  delay_state: number;
  staff_no: string;
  product_name: string;
  content: string;
  iteration_id: number;
  real_begin_time: string;
  demand_id: number;
  is_sys: boolean;
  name: string;
  id: number;
  status: number;
  real_hours: number;
}

export interface BugReqSearch {
  product_i?: string; // 项目ID
  name?: string; // 名称搜索关键字
  status?: number[]; // 状态，0待解决;1进行中;2已解决;3已关闭;4延期处理;5不予处理;6重新打开;999已作废;
  create_by?: string; // 创建人工号
  staff_no?: string; // 指派给工号
  priotity?: number[]; // bug优先级  1.P0  2.P1 3,P2 4.P3 5.P4
  level?: number[]; // 级别  1一般;2中等;3严重
  type?: number[]; // bug类型  1.代码错误;2.需求设计;3.界面优化;4.配置相关;5.性能问题;6.校验规范;7.测试脚本;8.其他;
  env?: number[]; // 环境 1.测试环境 2.uat环境  3.线上环境
  iteration_id?: number; // 迭代ID
  demand_id?: string; // 需求ID
  task_id?: string; // 任务ID
  test_case_id?: string; // 测试用例ID
  create_time?: string; // 创建时间
  end_time?: string; // 结束时间
  sort_name?: string; // 排序名称
  sort_by?: number; // 排序值  1.正序 2.倒序
  page_index?: number; // 页码
  page_size?: number; // 每页数量
}

export interface BugListResp {
  unsolved_num: number; // 未解决记录数;
  unvalidated_num: number; // 未验证记录数;
  serious_num: number; // 严重bug数;
  count: number; // 总记录数;
  list: BugListRespItem[];
}

export interface BugListRespItem {
  id: number; // bug ID;
  name: string; // bug名称;
  level: number; // 级别：1一般;2中等;3严重;
  env: number; // 环境 1.测试环境 2.uat环境  3.线上环境;
  status: number; // 状态：0待解决;1进行中;2已解决;3已关闭;4延期处理;5不予处理;6重新打开;999已作废;;
  staff_no: string; // 指派员工工号;
  staff_name: string; // 指派员工姓名;
  end_time: string; // 解决日期;
  create_by: string; // 创建人工号;
  creator: string; // 创建人名字;
  create_time: string; // 创建时间;
  priority: number; // 优先级  1.P0  2.P1 3,P2 4.P3 5.P4;,
  product_id: number;
  iteration_id: number;
}

export interface ProfileOverviewResp {
  staff_name: string; // 员工姓名
  iteration_nums: number; // 参与迭代数;
  doing_iteration_nums: number; // 进行中迭代数
  working_days: number; // 在职天数
  sex: number; // 性别，0未知，1男，2女
  avatar: string; // 头像;
  employee_day: string; // 入职时间;
  product_nums: number; // 参与项目数;
  staff_no: string;
  iteration_punctuality: number; // 迭代准点率;
  job_name: string; // 职位名称;
  id: number;
  department: string; // 部门;
  finished_itertion_nums: number; // 完成的迭代数
}

export interface ProfileOverviewListResp {
  serious_bug_num: number; // 严重BUG数量
  iteration_nums: number; // 参与迭代数量
  delete_time: string; // 退出时间;
  create_time: string; // 加入时间;
  task_punctuality: number; // 任务准时率
  bug_nums: number; // BUG数量
  defect_density: number; // 缺陷密度
  product_id: number; // 项目ID
  bug_effective: number; // BUG有效率
  product_name: string; // 项目名称;
  finished_task_nums: number; // 完成任务数量
  created_bug_nums: number; // 创建BUG数量
}

export interface ProfileOverviewSearch {
  staff_no?: string; // 员工编号
  product_id?: string; // 项目ID
  iteration_id?: string; // 迭代ID
  month?: string; // 月份
}

export interface ProfileOverviewInfo {
  high_level_bug_nums: number; // 严重bug数量
  avg_survival_time: number; // 平均存活时长
  created_test_env_bug_nums: number; // 创建的测试环境bug数量
  bug_nums: number; // bug总数量
  uat_env_bug_nums: number; // uat环境bug数量
  defect_density: number; // 缺陷密度
  finished_task_hours: number; // 已完成任务工时
  iteration_punctuality: number; // 迭代准点率
  middle_level_bug_nums: number; // 中等bug数量
  created_normal_level_bug_nums: number; // 创建的一般bug数量
  done_task_status_nums: number; // 已完成任务数量
  normal_level_bug_nums: number; // 一般bug数量
  closed_task_status_nums: number; // 已关闭任务数量
  created_unsolved_bug_nums: number; // 创建的未解决bug数量
  created_high_level_bug_nums: number; // 创建的严重bug数量
  test_env_bug_nums: number; // 测试环境bug数量
  created_middle_level_bug_nums: number; // 创建的中等bug数量
  task_total_hours: number; // 任务总工时
  iteration_nums: number; // 参与的迭代数量
  task_nums: number; // 任务总数量
  task_punctuality: number; // 任务准点率
  doing_task_status_nums: number; // 进行中任务数量
  pro_env_bug_nums: number; // 生产环境bug数量
  paused_task_status_nums: number; // 已搁置任务数量
  created_pro_env_bug_nums: number; // 创建的生产环境bug数量
  unsolved_bug_nums: number; // 未解决bug数量
  bug_efficiency: number; // bug有效率
  created_uat_env_bug_nums: number; // 创建的uat环境bug数量
  pending_task_status_nums: number; // 未开始任务数量
  avg_response_time: number; // 平均响应时长
  created_bug_nums: number; // 创建的bug总数量
}

export interface ProfileProjectItem {
  serious_bug_num: number; // 严重BUG数量
  iteration_nums: number; // 参与迭代数量
  delete_time: string; // 退出时间
  create_time: string; // 加入时间
  task_punctuality: number; // 任务准时率
  bug_nums: number; // BUG数量
  defect_density: number; // 缺陷密度
  product_id: number; // 项目ID
  bug_effective: number; // BUG有效率
  product_name: string; // 项目名称
  finished_task_nums: number; // 完成任务数量
  created_bug_nums: number; // 创建BUG数量
}

export interface ProfileOverviewCharts {
  hour_burn_down_line: {
    // 工时燃尽图
    title: string; // 标题
    category: string[]; // X轴
    list: {
      data: any[];
      label: string;
    }[];
  };
  bug_count_burn_down_line: {
    // bug燃尽图
    title: string; // 标题
    category: string[]; // X轴
    list: {
      data: any[];
      label: string;
    }[];
  };
}

export default function useProfileOverview() {
  // 编辑需求说明
  const useGetQualityIterateList = async (params?: IteraterReqSearch): Promise<QualityResp | null> => {
    const [err, data]: [unknown, QualityResp | null] = await awaitFunc<IteraterReqSearch, QualityResp>({
      asyncFunc: getQualityIterateList,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 编辑需求说明
  const useGetTaskList = async (params: TaskReqSearch): Promise<TaskList | null> => {
    const [err, data]: [unknown, TaskList | null] = await awaitFunc<TaskReqSearch, TaskList>({
      asyncFunc: getTaskList,
      args: params
    });
    if (err || !data) return null;
    return data;
  };
  // 编辑需求说明
  const useGetProfileInfo = async (params: { staff_no: string | undefined }): Promise<ProfileOverviewResp | null> => {
    const [err, data]: [unknown, ProfileOverviewResp | null] = await awaitFunc<{ staff_no: string | undefined }, ProfileOverviewResp>({
      asyncFunc: getProfileInfoApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 编辑需求说明
  const useGetBugList = async (params: BugReqSearch): Promise<BugListResp | null> => {
    const [err, data]: [unknown, BugListResp | null] = await awaitFunc<BugReqSearch, BugListResp>({
      asyncFunc: getBugListApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };
  // 获取个人主页-项目列表
  const useGetProfileList = async (params: {
    staff_no?: string;
    page_index?: number;
    page_size?: number;
  }): Promise<TableListBaseResp<ProfileProjectItem> | null> => {
    const [err, data]: [unknown, TableListBaseResp<ProfileProjectItem> | null] = await awaitFunc<
      { staff_no?: string; page_index?: number; page_size?: number },
      TableListBaseResp<ProfileProjectItem>
    >({
      asyncFunc: getProfileProjectListApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 个人主页-总览
  const useGetProfileOverviewInfo = async (params: ProfileOverviewSearch): Promise<ProfileOverviewInfo | null> => {
    const [err, data]: [unknown, ProfileOverviewInfo | null] = await awaitFunc<ProfileOverviewSearch, ProfileOverviewInfo>({
      asyncFunc: getProfileOverviewInfoApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 个人主页-燃尽图
  const useGetProfileOverviewCharts = async (params: ProfileOverviewSearch): Promise<ProfileOverviewCharts | null> => {
    const [err, data]: [unknown, ProfileOverviewCharts | null] = await awaitFunc<ProfileOverviewSearch, ProfileOverviewCharts>({
      asyncFunc: getProfileOverviewChartsApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 获取某一个迭代的文案和color
  const getIterateTextStatus = (status: number) => {
    const item = STATUS.find((v: { value: number; label: string }) => v.value === status);
    if (item) {
      return item;
    }
  };
  // 获取迭代的当前状态
  const getIterateCurPercentStatus = (params: { complete_percent: number; time_percent: number }) => {
    const diff = params.complete_percent - params.time_percent;
    if (diff > 0) {
      return {
        value: "提前",
        color: "#49b513",
        class: "percent-lead",
        percent: `${Math.abs(diff)}%`
      };
    } else {
      return {
        value: "落后",
        color: "#ff1d1d",
        class: "percent-behind",
        percent: `${Math.abs(diff)}%`
      };
    }
  };

  return {
    useGetProfileOverviewCharts,
    useGetProfileOverviewInfo,
    useGetProfileInfo,
    useGetQualityIterateList,
    useGetTaskList,
    useGetBugList,
    getIterateCurPercentStatus,
    getIterateTextStatus,
    useGetProfileList
  };
}
